import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, Tooltip, ZoomControl } from "react-leaflet";

// Custom icon setup for the marker
const busIcon = L.divIcon({
  className: "custom-icon",
  iconSize: [10, 10],
  iconAnchor: [10, 10],
  html: '<div class="icon" style="color: #056608; background-color: rgb(255, 255, 255);"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path d="M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8c-13.25 0-24 10.74-24 24v80c0 13.25 10.75 24 24 24h8v160c0 17.67 14.33 32 32 32v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h192v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h6.4c16 0 25.6-12.8 25.6-25.6V256h8c13.25 0 24-10.75 24-24v-80c0-13.26-10.75-24-24-24zM160 72c0-4.42 3.58-8 8-8h176c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H168c-4.42 0-8-3.58-8-8V72zm-48 328c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128-112H128c-17.67 0-32-14.33-32-32v-96c0-17.67 14.33-32 32-32h112v160zm32 0V128h112c17.67 0 32 14.33 32 32v96c0 17.67-14.33 32-32 32H272zm128 112c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path></svg></div>',
});

const VehicleMap = ({ newVehicleLocation, telematicsIds }) => {
  const [currentLocation, setCurrentLocation] = useState([]);

  useEffect(() => {
    if (telematicsIds && newVehicleLocation !== undefined) {
      const data = newVehicleLocation.filter((item) => telematicsIds.includes(item.telematics_id));
      setCurrentLocation(data);
    }
  }, [newVehicleLocation, telematicsIds]);

  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
      <MapContainer
        center={[-1.2832533, 36.8172449]}
        zoom={13}
        style={{ width: "100%", height: "100%" }}
        zoomControl={false} // Disable default zoom control
      >
        <TileLayer url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" />
        <ZoomControl position="bottomright" /> {/* Custom zoom control position */}
        {currentLocation.length > 0 ? (
          currentLocation.map((vehicle) => {
            if (vehicle.latitude != null && vehicle.longitude != null) {
              const registrationNumber = vehicle.registration_number || "No registration number"; // Fallback message if missing
              return (
                <Marker key={vehicle.telematics_id} position={[vehicle.latitude, vehicle.longitude]} icon={busIcon}>
                  <Tooltip direction="top" permanent interactive={true}>
                    <strong>{registrationNumber}</strong>
                  </Tooltip>
                </Marker>
              );
            }
            return null; // Skip rendering marker if latitude or longitude is null
          })
        ) : (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 99,
              backgroundColor: "rgba(211, 211, 211, 0.5)",
            }}
          >
            <div className="loader">Loading...</div>
          </div>
        )}
      </MapContainer>
    </div>
  );
};

export default VehicleMap;
